import React, { useContext, useEffect, useState } from "react";
import Modal from "../../modal/Modal";
import { ScaleLoader } from "react-spinners";
import TextAreaBox from "../../../layouts/TextAreaBox";
import InitialsLogo from "../../all/InitialsLogo";
import RichEditor from "../../all/RichEditor/RichEditor";
const core = require("../../../lib/core");
import parse from "html-react-parser";

function AddNotes({ candidateDetail, jobId, modalDismiss, setContentReload }) {
  // const [notes, setNotes] = useState();
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});
  const [mentionedUsers, setMentionedUsers] = useState(new Set());

  function parseHtml(html) {
    const parsedHtml = parse(html.split("&lt;").join("<"));
    return parsedHtml;
  }

  function formatDate(dateString) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const date = new Date(dateString);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    // Pad minutes with leading zero if necessary
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    const formattedDate = `${day} ${months[monthIndex]} ${year} ${hours}:${formattedMinutes}`;
    return formattedDate;
  }

  const { user } = useContext(core.UserContext);

  useEffect(() => {
    console.log("User candidate:", candidateDetail);
    if (Array.isArray(candidateDetail.notes)) {
      setNotes(candidateDetail.notes);
    } else if (Array.isArray(candidateDetail.name)) {
      setNotes(candidateDetail.name);
    } else if (typeof candidateDetail.name === "string") {
      setNotes([
        {
          note: candidateDetail.name,
          name: user?.name ?? user?.email,
          date: new Date().toLocaleString(),
        },
      ]);
    } else {
      setNotes([]);
    }
  }, [candidateDetail]);

  const [text, setText] = useState("");

  console.log(candidateDetail);

  const extractMentionsFromContent = (content) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = content;

    const mentionSpans = tempDiv.querySelectorAll('span[data-type="mention"]');
    const mentionedEmails = new Set();

    mentionSpans.forEach((span) => {
      const userId = span.getAttribute("data-id");
      if (userId) {
        mentionedEmails.add(userId);
      }
    });

    return mentionedEmails;
  };

  const PostNotification = async (userId, message) => {
    try {
      await core.API(
        core.API_METHODS.POST,
        core.USER_API.POST_ADD_NOTIFICATION,
        1,
        {
          user_id: userId,
          message: message,
          type: "info",
          matchprofile: candidateDetail?.id || candidateDetail?._id,
          name: user?.name ?? user?.email,
        },
      );
    } catch (error) {
      console.error("Error posting notification:", error);
    }
  };

  const updateNotes = async (type) => {
    try {
      setMessage({});
      setLoading(true);
      const json = await core.API(
        core.API_METHODS.PUT,
        core.RECRUITER_API.PUT_MATCHPROFILE_FEEDBACK,
        1,
        {
          jobId: jobId,
          matchProfileId: candidateDetail?.id || candidateDetail?._id,
          humanMatchScore: candidateDetail?.humanMatchScore,
          thumbs_up: candidateDetail?.vote === false ? 2 : 1,
          latestnote: text,
          notes:
            text.length > 0
              ? [
                  ...notes,
                  {
                    note: text,
                    name: user?.name ?? user?.email,
                    date: new Date(),
                  },
                ]
              : notes,
        },
      );

      if (json?.data) {
        setLoading(false);
        setContentReload(json?.data);
        modalDismiss();

        if (text.length > 0) {
          const newNote = {
            note: text,
            name: user.name ?? user.email,
            date: new Date().toLocaleString(),
          };

          setNotes((prevNotes) => [...prevNotes, newNote]);
          setText("");

          const mentionedEmails = extractMentionsFromContent(newNote.note);
          setMentionedUsers(mentionedEmails);

          if (mentionedEmails.size > 0) {
            const mentionPromises = Array.from(mentionedEmails).map(
              (userEmail) =>
                PostNotification(
                  userEmail,
                  `You were mentioned in a note by ${user.name ?? user.email}`,
                ),
            );
            await Promise.all(mentionPromises);
          }
        }
      } else {
        setLoading(false);
        setMessage({
          type: "error",
          message: json?.error?.message,
        });
      }
    } catch (error) {
      setLoading(false);
      setMessage({
        type: "error",
        message: "Something went wrong. Please try again",
      });
    }
  };

  const handleDeleteNote = (index) => {
    setNotes((prevNotes) => {
      const updatedNotes = [...prevNotes];
      updatedNotes.splice(index, 1);
      return updatedNotes;
    });
  };

  return (
    <Modal
      heading={"Notes"}
      subheading={"Add feedback for this candidate"}
      onSave={updateNotes}
      modalDismiss={modalDismiss}
      defaultButtons={{
        primaryDisabled: loading,
        primaryLabel: "Save",
      }}
      isLoading={loading}
      className="min-w-[900px]"
    >
      {/* <textarea
        placeholder="Type here..."
        value={notes}
        name=""
        id=""
        className="w-full h-[200px] bg-white border border-[#E1E1EE]
rounded-lg resize-none"
        onChange={(e) => setNotes(e?.target?.value)}
      ></textarea> */}

      <div className="">
        <div>
          {notes &&
            notes?.map((note, index) => (
              <div key={index} className="mb-4  bg-gray-100 rounded">
                <div className="flex gap-4">
                  <InitialsLogo
                    str={note?.name}
                    borderRadius="50%"
                    width="30px"
                    height="30px"
                  />
                  <div className="flex flex-col">
                    <span className="flex items-center gap-4">
                      {" "}
                      <span
                        className=" text-sm not-italic  text-gray-800 truncate
font-semibold"
                      >
                        {note.name}
                      </span>{" "}
                      -{" "}
                      <span
                        className=" text-sm
not-italic  text-gray-800 truncate font-medium"
                      >
                        {note?.date && formatDate(note?.date)}
                      </span>
                      <button
                        onClick={() => handleDeleteNote(index)}
                        className=" cursor-pointer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                          fill="none"
                        >
                          <path
                            d="M3.33333 5.83398V12.5007C3.33333 13.9734
4.52724 15.1673 6 15.1673H10C11.4728 15.1673 12.6667 13.9734 12.6667
12.5007V5.83398M9.33333 7.83398V11.834M6.66667 7.83398L6.66667
11.834M10.6667 3.83398L9.72916 2.42772C9.48187 2.05679 9.06556 1.83398
8.61975 1.83398H7.38025C6.93444 1.83398 6.51813 2.05679 6.27084
2.42772L5.33333 3.83398M10.6667 3.83398H5.33333M10.6667
3.83398H14M5.33333 3.83398H2"
                            stroke="#FF475D"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </span>
                    <span className="text-sm text-[#5F6989]  prose !min-w-full ">
                      {parseHtml(note.note)}
                    </span>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className=" ">
          {/* <TextAreaBox
            placeholder="Write a note here ..."
            value={text}
            name=""
            id=""
            onChange={(e) => setText(e.target?.value)}
          /> */}
          <RichEditor
            withToolbar={true}
            content={text}
            onEditorChange={(newContent) => {
              setText(newContent);
              const mentionedEmails = extractMentionsFromContent(newContent);
              setMentionedUsers(mentionedEmails);
            }}
          />
        </div>
      </div>
    </Modal>
  );
}

export default AddNotes;
