import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Link from "@tiptap/extension-link";
import TextAlign from "@tiptap/extension-text-align";
import Image from "@tiptap/extension-image";
import TaskList from "@tiptap/extension-task-list";
import TaskItem from "@tiptap/extension-task-item";
import TextStyle from "@tiptap/extension-text-style";
import FontFamily from "@tiptap/extension-font-family";
import FontSize from "tiptap-extension-font-size";
import { Mention } from "@tiptap/extension-mention";
import suggestion from "./Suggestion";
import { Toolbar } from "./Toolbar";
import "./style/RichEditor.scss";

const RichEditorWithKey = forwardRef(
  (
    {
      onEditorChange,
      content = "",
      editable = true,
      withToolbar = false,
      withLinkExtension = true,
      withCodeBlockLowlightExtension = false,
      withTaskListExtension = false,
      onFocus,
      userEmails = [],
    },
    ref,
  ) => {
    const [lastUploadedImageSize, setLastUploadedImageSize] = useState({
      height: "auto",
      width: "auto",
    });

    const dropdownRef = useRef(null);

    const extensions = [
      StarterKit.configure({
        ...(withCodeBlockLowlightExtension && { codeBlock: false }),
        bulletList: {
          keepMarks: true,
          keepAttributes: false,
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false,
        },
        paragraph: {
          HTMLAttributes: {
            style: "margin: 0 0; padding: 0; min-height: 20px;",
          },
          renderHTML({ HTMLAttributes }) {
            return ["div", HTMLAttributes, 0];
          },
        },
        blockquote: {
          HTMLAttributes: {
            style:
              "padding-left: 1rem;border-left: 2px solid rgba(#0d0d0d, 0.1)",
          },
        },
        codeBlock: {
          HTMLAttributes: {
            style:
              'color: #24292e; background: #f5f7f9;font-family: "ui-monospace", "SFMono-Regular", "SF Mono", Menlo, Consolas, "Liberation Mono", monospace; padding: 0.75rem 1rem; border-radius: 0.5rem;',
          },
        },
        image: {
          HTMLAttributes: {
            style: `height: ${lastUploadedImageSize.height}px; width: ${lastUploadedImageSize.width}px; margin: 1em 0;`,
          },
        },
        horizontalRule: {
          HTMLAttributes: {
            style: "margin: 0.5em 0; border-top-width:2px;",
          },
        },
      }),
      Underline,
      Image.extend({
        addAttributes() {
          return {
            src: {
              default: "",
            },
            alt: {
              default: undefined,
            },
            title: {
              default: undefined,
            },
            width: {
              default: undefined,
            },
            height: {
              default: undefined,
            },
            style: {
              default: undefined,
            },
            link: {
              default: undefined,
              renderHTML: (attributes) => {
                if (!attributes.link) return {};
                return {
                  dataLink: attributes.link,
                };
              },
              parseHTML: (element) => element.getAttribute("dataLink"),
            },
          };
        },
        renderHTML({ HTMLAttributes }) {
          const { dataLink, ...rest } = HTMLAttributes;
          if (dataLink) {
            return [
              "div",
              { style: "display: block;" },
              ["a", { href: dataLink, target: "_blank" }, ["img", rest]],
            ];
          }
          return ["div", { style: "display: block;" }, ["img", rest]];
        },
      }),
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),
      TextStyle,
      FontFamily,
      FontSize.configure({
        types: ["textStyle"],
        defaultSize: "16px",
        sizes: [
          "8px",
          "10px",
          "12px",
          "14px",
          "16px",
          "18px",
          "20px",
          "24px",
          "30px",
          "36px",
          "48px",
          "60px",
          "72px",
        ],
      }),
      Mention.configure({
        HTMLAttributes: {
          class: "mention",
        },
        suggestion,
        renderLabel({ options, node }) {
          return `@${node.attrs.label || "Unknown"}`;
        },
        addAttributes() {
          return {
            label: {
              default: null,
              parseHTML: (element) => element.getAttribute("data-label"),
              renderHTML: (attributes) => {
                return {
                  "data-label": attributes.label,
                };
              },
            },
            id: {
              default: null,
              parseHTML: (element) => element.getAttribute("data-id"),
              renderHTML: (attributes) => {
                return {
                  "data-id": attributes.id,
                };
              },
            },
            email: {
              default: null,
              parseHTML: (element) => element.getAttribute("data-email"),
              renderHTML: (attributes) => {
                return {
                  "data-email": attributes.email,
                };
              },
            },
          };
        },
      }),
    ];

    if (withLinkExtension) {
      extensions.push(
        Link.configure({
          linkOnPaste: false,
          openOnClick: false,
        }),
      );
    }

    if (withTaskListExtension) {
      extensions.push(TaskList, TaskItem);
    }

    const [editorHtmlContent, setEditorHtmlContent] = React.useState(
      content.trim(),
    );

    const editor = useEditor({
      content: content
        .trim()
        ?.replaceAll("&lt;", "<")
        ?.replaceAll("&quot;", '"')
        ?.startsWith("<div>")
        ? "<p>" +
          content
            .trim()
            ?.replaceAll("&lt;", "<")
            ?.replaceAll("&quot;", '"')
            ?.replaceAll("<div>", "<p>")
            ?.replaceAll("</div>", "</p>") +
          "</p>"
        : content.trim()?.replaceAll("&lt;", "<")?.replaceAll("&quot;", '"'),
      extensions,
      editable,
      editorProps: {
        attributes: {
          style: "min-height: 100px;",
        },
      },
      onUpdate: ({ editor }) => {
        setEditorHtmlContent(editor.getHTML());
        onEditorChange(editor.getHTML());
        handleMentionsUpdate(editor);
      },
      parseOptions: {
        preserveWhitespace: true,
      },
    });

    useEffect(() => {
      if (editor && content !== editor.getHTML()) {
        editor.commands.setContent(
          content.trim()?.replaceAll("&lt;", "<")?.startsWith("<div>")
            ? "<p>" +
                content
                  .trim()
                  ?.replaceAll("&lt;", "<")
                  ?.replaceAll("&quot;", '"')
                  ?.replaceAll("<div>", "<p>")
                  ?.replaceAll("</div>", "</p>") +
                "</p>"
            : content
                .trim()
                ?.replaceAll("&lt;", "<")
                ?.replaceAll("&quot;", '"'),
        );
      }
    }, [content, editor]);

    if (!editor) {
      return null;
    }

    const editorRef = ref || useRef(null);

    const appendTextAtCursor = (text) => {
      if (editor && editor.isEditable) {
        editor.chain().focus().insertContent(text).run();
      }
    };

    useImperativeHandle(editorRef, () => ({
      appendText: appendTextAtCursor,
    }));

    return (
      <>
        <div
          className="border border-gray-500 rounded-lg flex flex-col gap-3"
          onClick={() => {
            editorRef.current?.firstChild?.focus();
          }}
        >
          {withToolbar ? (
            <Toolbar
              editor={editor}
              setLastUploadedImageSize={setLastUploadedImageSize}
            />
          ) : null}
          <div className="p-2 relative">
            <EditorContent
              className="max-h-[300px] overflow-y-auto prose min-w-full cursor-text"
              rows={5}
              editor={editor}
              ref={editorRef}
              innerRef={editorRef}
              defaultRows={2}
              onFocus={onFocus}
              style={{
                minHeight: "100px",
              }}
            />
          </div>
        </div>
      </>
    );
  },
);

// Add required styles
const styles = `
.mention {
  background-color: #e5f2ff;
  border-radius: 0.25rem;
  padding: 0.125rem 0.25rem;
  color: #1a73e8;
  white-space: nowrap;
  font-weight: 500;
}

.mentions-menu {
  max-height: 300px;
  overflow: auto;
}
`;

const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

const RichEditor = React.memo(RichEditorWithKey);

export default RichEditor;
