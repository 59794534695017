import React, { useEffect, useState, useContext } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import { toast } from "react-toastify";
import Sprouts from "../../components/assets/sprouts.png";
import AvailabilityHeader from "./AvailabilityHeader";
import TextAreaBox from "../../layouts/TextAreaBox";
const core = require("../../lib/core");

function JobApproval() {
  // const { id, userid } = useParams();
  const [searchParams] = useSearchParams();
  const statusFromQuery = searchParams.get("action");
  const [isLoading, setIsLoading] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  // const { user } = useContext(core.UserContext);
  const [userName, setUserName] = useState("Guest");
  const [apiResponseReceived, setApiResponseReceived] = useState(false);

  const jobId = searchParams.get("job");
  const token = searchParams.get("token");
  const action = searchParams.get("action");
  const [approvalStatus, setApprovalStatus] = useState(action);

  // useEffect(() => {
  //   if (user) {
  //     setUserName(user.name);
  //   }
  // }, [user]);

  useEffect(() => {
    setApprovalStatus(statusFromQuery || "");
  }, [statusFromQuery]);

  const handleJobStatusUpdate = async (status, notes = "") => {
    setIsLoading(true);
    try {
      const json = await core.API(
        core.API_METHODS.POST,
        core.JOB_API.POST_JOB_APPROVAL_STATUS,
        2,
        { jobId: jobId, status: action, notes },
      );

      if (json?.data) {
        if (status === "approved") {
          toast.success("Job has been successfully approved!");
        } else if (status === "rejected") {
          toast.success("Rejection has been submitted successfully!");
        }
        setApprovalStatus(status);
        setApiResponseReceived(true);
      } else if (json?.code === 400 && json?.message) {
        toast.error(
          "You already responded to this request. Please resend again",
        );
        setApprovalStatus("error");
      } else {
        toast.error("Error while processing your request");
      }
    } catch (error) {
      console.error("Error during API call:", error);
      toast.error("An error occurred while processing your request");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (approvalStatus === "approved") {
      handleJobStatusUpdate("approved");
    }
  }, [approvalStatus]);

  const handleRejectionSubmit = (event) => {
    event.preventDefault();
    handleJobStatusUpdate("rejected", rejectionReason);
  };

  return (
    <div className="font-sans h-full flex flex-col overflow-auto items-center bg-[#F3F5F9]">
      <AvailabilityHeader
        orgDetails={{
          domain_url: "https://www.sproutsai.com",
          logo: "https://sproutsweb-assets.s3.amazonaws.com/organization/sproutsai-d107a.svg",
        }}
        fixHeader="https://sproutsweb-assets.s3.amazonaws.com/organization/sproutsai-d107a.svg"
      />

      <div className="w-11/12 flex justify-center mt-[20px] h-screen">
        {isLoading ? (
          <div className="mx-auto md:w-2/3 py-10 flex justify-center items-center">
            <ScaleLoader height={40} color="#1369E9" loading={true} />
          </div>
        ) : (
          <div className="w-11/12">
            <div className="rounded-[12px] bg-white shadow-PipelineCardsShadow py-[18px] px-4">
              <h1 className="font-semibold text-[25px] not-italic text-[#16182F] mb-4">
                Job Approval
              </h1>
              {/* <p className="text-gray-800 text-md font-semibold not-italic mb-6">
                Hello, {userName} 👋
              </p> */}

              {approvalStatus === "approved" && apiResponseReceived ? (
                <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-6">
                  <p className="font-bold">Thank you for the confirmation!</p>
                  <p>Your job approval has been approved successfully.</p>
                </div>
              ) : approvalStatus === "rejected" && apiResponseReceived ? (
                <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-6">
                  <p className="font-bold">Rejection Submitted</p>
                  <p>Your job rejection has been submitted successfully.</p>
                </div>
              ) : approvalStatus === "error" ? (
                <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-6">
                  <p className="font-bold">Action Not Allowed</p>
                  <p>
                    You cannot change the status of an already approved or
                    rejected approver.
                  </p>
                </div>
              ) : approvalStatus === "rejected" ? (
                <>
                  <div className="space-y-4">
                    <div>
                      <label
                        htmlFor="rejectionReason"
                        className="text-sm font-normal  text-gray-800 not-italic"
                      >
                        Reason for Rejection
                      </label>
                      <TextAreaBox
                        id="rejectionReason"
                        placeholder={"Enter the reason for the rejection"}
                        size={"150px"}
                        name="rejectionReason"
                        value={rejectionReason}
                        onChange={(e) => setRejectionReason(e.target.value)}
                      />
                    </div>
                    <div className="flex justify-end">
                      <button
                        onClick={handleRejectionSubmit}
                        type="submit"
                        className="bg-blue-800 text-white px-4 py-2 rounded bg-blue-800 "
                      >
                        Reject
                      </button>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        )}
      </div>

      <div className="md:absolute md:bottom-0 w-full md:mt-10 mt-auto">
        <footer className="bg-gray-200 pt-5  pb-4">
          <div className="container mx-auto flex items-center justify-center">
            <p className="text-gray-600 mr-1">Powered By</p>
            <a href="https://www.sproutsai.com/">
              <img
                src={Sprouts}
                alt="Sprouts Logo"
                className="w-28 h-auto ml-2 mb-1 pb-1"
              />
            </a>
          </div>
          <div className="text-center mt-2">
            <p className="text-sm no-underline">
              Read our{" "}
              <a
                href="https://www.sproutsai.com/privacy"
                className="text-blue-600 hover:no-underline no-underline hover:text-blue-900 hover:font-medium transition-all duration-300"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </p>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default JobApproval;
