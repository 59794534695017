import React, { useContext, useEffect, useRef } from "react";
import SvgIcon3 from "./icons/SvgIcon3";
import SvgIcon5 from "./icons/SvgIcon5";
import SvgIcon6 from "./icons/SvgIcon6";
import SvgIcon9 from "./icons/SvgIcon9";
import InitialsLogo from "../../all/InitialsLogo";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ScheduleInterview from "../applicant/ScheduleInterview";
import InviteModal from "../job/assessment/InviteModal";
import { CandidatePageContext } from "../../../lib/context";
import {
  CodingProfile,
  Facebook,
  Github,
  Instagram,
  notes,
  Portfolio,
  X_twitter,
} from "./icons/Icons";
import ChangeCandidatesDataModal from "../job/candidates/ChangeCandidatesDataModal";
import { updateProfile_MatchProfile } from "../../../redux/slices/JobMatchCandidatesSlice";
import RejectedReasonModal from "../applicant/RejectedReasonModal";
import ApplyForJobModal from "../../../screens/recruiter/candidate/ApplyForJobModal";
import SendEmail from "../applicant/SendEmail";

import { IoMdThumbsDown, IoMdThumbsUp } from "react-icons/io";
import { LuThumbsDown, LuThumbsUp } from "react-icons/lu";

import AddNotes from "../applicant/AddNotes";
import { VscGitStashApply } from "react-icons/vsc";
import { MdOutlinePreview } from "react-icons/md";
import ProtectedComponent from "../../../layouts/ProtectedComponent";
import { MdBusinessCenter } from "react-icons/md";
import UploadLinksModal from "./UploadLinksModal";
import { LinkIcon } from "lucide-react";

const core = require("../../../lib/core");

const TopBanner = ({
  isCandidateSection,
  setContentReload,
  updateLocalStatus,
  setActiveTab,
  btnList,
  candId,
  setCandidates,
  reviewMode,
  pipeline,
  showActivities = true,
  showActions = true,
  checkResource = false,
  showEditButton,
  setReload,
}) => {
  const { setShowModal } = useContext(CandidatePageContext);
  const [activeTabName, setActiveTabName] = useState("Details");
  const [showMenu, setShowMenu] = useState(false);
  const Profile = useSelector((state) => state?.jobCandidateMatch?.profile);
  const [Modal, setModal] = useState("");
  const [Links, setLinks] = useState([]);
  const dispatch = useDispatch();
  const [profile, setprofile] = useState(Profile);

  const handleCandidateEdit = (updatedData) => {
    dispatch(
      updateProfile_MatchProfile({
        email: updatedData.email,
        phone: updatedData.phone,
        work_email: updatedData.work_email,
        first_name: updatedData.first_name,
        last_name: updatedData.last_name,
        title: updatedData.title,
        resume_link: updatedData.resume_link,
        current_source: updatedData.current_source,
        remote_source: updatedData.remote_source,
        linkedin: updatedData.linkedin,
      }),
    );
  };
  const fetchExistingPortfolios = async () => {
    try {
      const response = await core.API(
        core.API_METHODS.GET,
        core.CANDIDATE_API.PORTFOLIO + "/" + Profile?.applicant_id,
        1,
        {},
      );

      if (response.code == 200 && Array.isArray(response?.data)) {
        const data = response.data;
        const existingLinks = data
          .filter((item) => item?.pinned === true)
          .map((item) => ({
            link: item.link,
            type: item.title,
          }));
        setLinks(existingLinks);
      }
    } catch (error) {
      console.error("Error fetching portfolio data:", error);
    } finally {
    }
  };

  useEffect(() => {
    fetchExistingPortfolios();
  }, [Profile]);
  const GetLinkIcon = (type) => {
    switch (type) {
      case "Facebook":
        return <Facebook width="100%" height="100%" />;
      case "X":
        return <X_twitter width="100%" height="100%" />;
      case "Instagram":
        return <Instagram width="100%" height="100%" />;
      case "Github":
        return <Github width="100%" height="100%" />;
      case "Coding platform":
        return <CodingProfile width="100%" height="100%" />;
      case "Portfolio":
        return <Portfolio width="100%" height="100%" />;
      default:
        return <LinkIcon width="100%" height="100%" />;
    }
  };
  const IconWrapper = ({ children, className = "" }) => {
    return (
      <div
        className={`inline-flex items-center justify-center w-8 h-8 rounded-lg bg-white border border-gray-500 ${className}`}
      >
        <div className="w-5 h-5 text-gray-700">{children}</div>
      </div>
    );
  };
  const [notesModalVisible, setNotesModalVisible] = useState(false);
  const [thumbsAction, setThumbsAction] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleThumbsPress = (isLike) => {
    setThumbsAction(isLike ? "like" : "dislike");
    setNotesModalVisible(true);
  };

  const modalDismiss = () => {
    setNotesModalVisible(false);
    setThumbsAction(null);
  };

  // const setContentReload = (newObj) => {
  //   setCandidates((prevData) =>
  //     prevData?.map((candidate) =>
  //       candidate._id === newObj._id ? { ...candidate, ...newObj } : candidate,
  //     ),
  //   );

  // };

  useEffect(() => {
    setprofile(Profile);
    // console.log("profile is", Profile);
  }, [Profile]);

  const [chatDetail, setChatDetail] = useState({});
  const [jobId, setJobId] = useState("");
  const [candidateDetail, setCandidateDetail] = useState({});
  const [sendText, setSendText] = useState("");

  const [applyDropDown, setApplyDropDown] = useState(-1);
  const [candiadtejobmodal, setCandidatejobmdal] = useState(null);
  const [UploadModal, setShowUploadDocs] = useState(false);
  const modalClose = () => {
    setModal("");
  };

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  async function updateStatus(
    cid,
    status,
    reason,
    email_template_id,
    rejection_reason_id,
    onBehalfId,
    when,
    send_email,
    subject,
  ) {
    setSendText("Sending...");
    await core
      .API(
        core.API_METHODS.PUT,
        core.RECRUITER_API.PUT_UPDATE_APPLICANT_STATUS,
        1,
        {
          matchprofileIds: [cid],
          jobId: profile?.matchProfile?.job,
          current_stage: status,
          rejectionNotes: reason,
          email_template_id: email_template_id,
          rejection_reason_id: rejection_reason_id,
          onBehalfId: onBehalfId,
          when: when,
          send_email: send_email,
          subject: subject,
        },
      )
      .then((json) => {
        if (json.data) {
          setprofile((prevData) => ({
            ...prevData,
            matchProfile: {
              ...prevData.matchProfile,
              current_stage: status,
            },
          }));
          setSendText("");
          modalClose();
          updateLocalStatus(cid, status);
        } else {
        }
      });
  }

  const handleModalDismiss = () => {
    setApplyDropDown(-1);
    setCandidatejobmdal(null);
  };

  const handleApplyClick = () => {
    setJobId(profile?.matchProfile?.job); // Set the current job ID
    setApplyDropDown(1);
    setCandidatejobmdal(profile?.applicant_id);
  };

  function formatLink(link) {
    // Check if the link starts with "https://" or "http://"
    if (!link.startsWith("https://") && !link.startsWith("http://")) {
      // If it doesn't, add "https://"
      link = "https://" + link;
    }

    return link;
  }

  return (
    // profile && (
    <>
      <div
        id="topBanner"
        className="w-full relative px-6 border-t-0 border-x-0 border-b border-[#e1e1ee] border-solid"
      >
        <div className="relative flex justify-start items-start flex-row gap-4 max-w-full w-full">
          <div className="relative grow flex flex-col justify-start items-start gap-2 w-auto">
            <div className="relative flex justify-start items-start flex-row gap-4">
              <InitialsLogo
                width="56px"
                height="56px"
                fontSize="24px"
                styleclassName="block rounded-xl border-[none]"
                str={profile?.matchProfile?.first_name}
              />
              <div className="relative grow flex flex-col flex-wrap max-w-full">
                <div className="flex items-center gap-4">
                  <span className="relative text-lg font-medium text-[#252e4b]">
                    {profile?.matchProfile?.first_name}{" "}
                    {profile?.matchProfile?.last_name ?? ""}
                  </span>
                  <div className="flex items-center gap-2.5">
                    {profile?.matchProfile?.linkedin && (
                      <a
                        className="btn-sec w-8 h-8 px-1"
                        href={formatLink(profile?.matchProfile?.linkedin)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-4 h-4"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M4.35313 3.55556C4.35295 3.89233 4.2138 4.21523 3.96629 4.45324C3.71878 4.69125 3.38318 4.82487 3.03333 4.8247C2.68347 4.82453 2.34801 4.69059 2.10075 4.45234C1.85348 4.21409 1.71467 3.89106 1.71484 3.55429C1.71502 3.21753 1.85417 2.89462 2.10168 2.65661C2.34919 2.41861 2.68479 2.28499 3.03465 2.28516C3.3845 2.28532 3.71996 2.41927 3.96723 2.65751C4.21449 2.89576 4.3533 3.2188 4.35313 3.55556ZM4.3927 5.76496H1.75442V13.7137H4.3927V5.76496ZM8.56119 5.76496H5.9361V13.7137H8.53481V9.54253C8.53481 7.21885 11.681 7.00299 11.681 9.54253V13.7137H14.2863V8.67909C14.2863 4.76184 9.6297 4.90787 8.53481 6.83157L8.56119 5.76496Z"
                            fill="#5F6989"
                          />
                        </svg>
                      </a>
                    )}
                    {profile?.matchProfile?.github && (
                      <a href={profile?.matchProfile?.github}>
                        <SvgIcon5 className="w-8 h-8" />
                      </a>
                    )}
                    {profile?.matchProfile?.email?.length > 0 && (
                      <button
                        className="cursor-pointer"
                        onClick={() => {
                          setModal("send-email");
                        }}
                      >
                        <SvgIcon6 className="w-8 h-8" />
                      </button>
                    )}

                    {showEditButton && (
                      <ProtectedComponent
                        showModal={true}
                        code={checkResource ? "Candidate_update" : "no_code"}
                      >
                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            setModal("Update_Can_data");
                          }}
                        >
                          <SvgIcon9 className="w-8 h-8" />
                        </div>
                      </ProtectedComponent>
                    )}
                    {Links?.length > 0 && (
                      <div className="flex flex-row gap-2 ">
                        {Links?.map((linkItem, idx) => {
                          return (
                            <IconWrapper>
                              <a
                                key={idx}
                                href={linkItem?.link}
                                data-tooltip-id="tooltip"
                                data-tooltip-content={linkItem?.type}
                                target={"_blank"}
                                rel="noreferrer"
                              >
                                {GetLinkIcon(linkItem.type)}
                              </a>
                            </IconWrapper>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
                {profile?.matchProfile?.title && (
                  <span className="text-sm overflow-ellipsis text-[#5f6989] mt-1.5">
                    {profile?.matchProfile?.title}
                  </span>
                )}
              </div>
            </div>
            {/* Links and buttons */}
            {/* <div className="flex justify-start items-stretch flex-row gap-2.5 h-8 mt-2"> */}
            {/* <div
              className={`bg-[rgba(0,172,133,0.10)] flex justify-start items-center flex-row gap-1.5 px-2 rounded-lg`}
            >
              <SvgIcon1 className="w-[18px] h-[18px] text-[#00ac85] flex" />
              <p className={` text-sm font-medium text-[#252e4b]`}>
                {profile?.matchProfile?.overallMatch * 100}% Match
              </p>
            </div> */}
            {/* {profile?.matchProfile?.linkedin && (
                <a
                  className="btn-sec w-8 h-8 px-1"
                  href={profile?.matchProfile?.linkedin}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 h-4"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M4.35313 3.55556C4.35295 3.89233 4.2138 4.21523 3.96629 4.45324C3.71878 4.69125 3.38318 4.82487 3.03333 4.8247C2.68347 4.82453 2.34801 4.69059 2.10075 4.45234C1.85348 4.21409 1.71467 3.89106 1.71484 3.55429C1.71502 3.21753 1.85417 2.89462 2.10168 2.65661C2.34919 2.41861 2.68479 2.28499 3.03465 2.28516C3.3845 2.28532 3.71996 2.41927 3.96723 2.65751C4.21449 2.89576 4.3533 3.2188 4.35313 3.55556ZM4.3927 5.76496H1.75442V13.7137H4.3927V5.76496ZM8.56119 5.76496H5.9361V13.7137H8.53481V9.54253C8.53481 7.21885 11.681 7.00299 11.681 9.54253V13.7137H14.2863V8.67909C14.2863 4.76184 9.6297 4.90787 8.53481 6.83157L8.56119 5.76496Z"
                      fill="#5F6989"
                    />
                  </svg>
                </a>
              )}
              {profile?.matchProfile?.github && (
                <a href={profile?.matchProfile?.github}>
                  <SvgIcon5 className="w-8 h-8" />
                </a>
              )}
              {profile?.matchProfile?.email?.length > 0 && showActions && (
                <a href={profile?.matchProfile?.email[0]}>
                  <SvgIcon6 className="w-8 h-8" />
                </a>
              )}

              {showActions && (
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    setModal("Update_Can_data");
                  }}
                >
                  <SvgIcon9 className="w-8 h-8" />
                </div>
              } */}
            {/* {!profile?.link && (
              )}
              {/* {!profile?.link && (
            <a href={profile?.link}>
              <SvgIcon7 className="w-8 h-8 flex" />
            </a>
          )} */}
            {/* Button Component is detected here. We've generated code using HTML. See other options in "Component library" dropdown in Settings */}
            {/* <button className="w-[78px] h-8 border bg-white shadow-[0px_1px_0px_rgba(0,0,0,0.04)] text-sm text-[#5f6989] cursor-pointer inline-flex items-center justify-center gap-1.5 rounded-lg border-[#e1e1ee] border-solid">
            <SvgIcon8 className="w-5 h-5 text-[#5f6989] flex" />
            Chat
          </button> */}
            {/* </div> */}
          </div>

          <div className="relative flex flex-col grow justify-end items-stretch gap-3 w-auto">
            {/* <div className="flex justify-end items-stretch flex-row gap-3 h-10">
              <button
                onClick={() => setShowMenu((prev) => !prev)}
                className="btn-md btn-sec shadow-[0px_2px_0px_rgba(0,0,0,0.04)]"
              >
                <span className="text-base text-[#252e4b]">
                  {profile?.matchProfile?.current_stage}
                </span>
                <SvgIcon3 className="w-4 h-4 text-[#252e4b] flex" />
                {showMenu && (
                  <div className="top-[40px] bg-white absolute w-[200px] p-1 rounded-lg border border-[#dadae4] shadow-[0px_2px_0px_rgba(0,0,0,0.04)]">
                    {pipeline
                      ?.filter((item) => item != "Rejected")
                      ?.map?.((stage) => (
                        <button
                          className="w-full text-sm text-gray-800 hover:bg-gray-300 px-2 py-1 text-left"
                          onClick={() => {
                            if (stage === "Rejected") {
                              setModal("reject-reason-status");
                              setChatDetail({
                                id: profile?.matchProfile?._id,
                                email: profile?.applicant?.email,
                                name: profile?.applicant?.first_name,
                              });
                            } else {
                              updateStatus(
                                profile?.matchProfile?._id,
                                stage,
                                ""
                              );
                            }
                          }}
                        >
                          {stage}
                        </button>
                      ))}
                  </div>
                )}
              </button>
              {profile?.matchProfile?.current_stage !== "Rejected" && (
                <button
                  onClick={(e) => {
                    setModal("reject-reason-status");
                    setChatDetail({
                      id: profile?.matchProfile?._id,
                      email: profile?.applicant?.email,
                      name: profile?.applicant?.first_name,
                    });
                  }}
                  className="btn-md btn-sec text-red-900 hover:bg-red-900 hover:text-white hover:border-red-900"
                >
                  Reject
                </button>
              )}
            </div> */}

            <div className="flex justify-end items-stretch flex-row gap-3 h-10 z-50">
              <div className="relative flex flex-col grow justify-end items-stretch gap-3 w-auto">
                <div className="flex justify-end items-stretch flex-row gap-3 h-10">
                  <button
                    onClick={() => setShowMenu((prev) => !prev)}
                    className="btn-md btn-sec shadow-[0px_2px_0px_rgba(0,0,0,0.04)]"
                    data-tooltip-id="tooltip"
                    data-tooltip-content={"Change stage"}
                    data-tooltip-place="left"
                  >
                    <span className="text-base text-[#252e4b]">
                      {profile?.matchProfile?.current_stage}
                    </span>

                    <span className="ml-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M6.71838 8.30751L6.71753 8.30657L5.18845 6.62649C5.17672 6.6136 5.17066 6.60014 5.16811 6.58407C5.1652 6.56574 5.16722 6.54403 5.17472 6.5241C5.17827 6.51464 5.18239 6.50742 5.18595 6.50247C5.18662 6.50154 5.18724 6.50072 5.18782 6.5L8.14833 6.5L10.8114 6.5C10.8151 6.50456 10.8205 6.51266 10.8253 6.52534C10.8331 6.54588 10.8351 6.56776 10.8323 6.58575C10.8299 6.60139 10.8241 6.61416 10.8129 6.62649L8.34541 9.33765C8.14851 9.55399 7.84854 9.55412 7.65152 9.33803C7.6514 9.33791 7.65128 9.33778 7.65116 9.33765L6.71838 8.30751Z"
                          fill="#252E4B"
                          stroke="#252E4B"
                        />
                      </svg>
                    </span>
                    {showMenu && (
                      <div
                        className="top-[40px] bg-white absolute w-[200px] p-1 rounded-lg border border-[#dadae4] shadow-[0px_2px_0px_rgba(0,0,0,0.04)]"
                        ref={dropdownRef}
                      >
                        {pipeline
                          ?.filter(
                            (stage) =>
                              stage !== profile?.matchProfile?.current_stage,
                          )
                          .map((stage) => (
                            <button
                              key={stage}
                              className={`w-full text-sm text-gray-800 hover:bg-gray-300 px-2 py-1 text-left ${
                                stage === "Rejected" ? "text-red-900" : ""
                              }`}
                              onClick={() => {
                                if (stage === "Rejected") {
                                  setModal("reject-reason-status");
                                  setChatDetail({
                                    id: profile?.matchProfile?._id,
                                    email: profile?.applicant?.email,
                                    name: profile?.applicant?.first_name,
                                  });
                                } else {
                                  updateStatus(
                                    profile?.matchProfile?._id,
                                    stage,
                                    "",
                                  );
                                }
                              }}
                            >
                              {stage}
                            </button>
                          ))}
                      </div>
                    )}
                  </button>
                </div>
              </div>

              {/* Invite candidate for assessment */}
              {/* {activeAssessments?.length > 0 && (
                <button
                  onClick={() => setShowModal({type:"assessment"})}
                  className="border shadow-[0px_2px_0px_rgba(0,0,0,0.04)] bg-white flex justify-center items-stretch flex-col px-2.5 rounded-lg border-[#dadae4] border-solid"
                >
                  <SvgIcon1 className="w-5 h-5 text-[#252e4b] flex" />
                </button>
              )} */}
              {/* Schedule interview candidate */}
              {/* <button
              data-tooltip-id="tooltip"
              data-tooltip-content={"Schedule interview"}
              onClick={() => setShowModal({ type: "interview", edit: false })}
              className="btn-md btn-sec w-10 px-2"
            >
              <SvgIcon2 className="w-5 h-5 text-[#252e4b] flex" />
            </button> */}
              <button
                data-tooltip-id="tooltip"
                data-tooltip-content={"Add links"}
                onClick={() => setShowUploadDocs(true)}
                className="btn-md btn-sec w-10 p-[9px] text-gray-700"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2em"
                  height="2em"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M17 20v-3h-3v-2h3v-3h2v3h3v2h-3v3zm-6-3H7q-2.075 0-3.537-1.463T2 12t1.463-3.537T7 7h4v2H7q-1.25 0-2.125.875T4 12t.875 2.125T7 15h4zm-3-4v-2h8v2zm14-1h-2q0-1.25-.875-2.125T17 9h-4V7h4q2.075 0 3.538 1.463T22 12"
                  />
                </svg>
              </button>
              <ProtectedComponent
                showModal={true}
                code={
                  checkResource ? "Candidate_general_feedback_write" : "no_code"
                }
              >
                <button
                  className="btn-md btn-sec w-10 p-[9px]"
                  data-tooltip-id="tooltip"
                  data-tooltip-content={"Like"}
                  onClick={() => handleThumbsPress(true)}
                >
                  {profile?.matchProfile?.thumbs_up === 1 ? (
                    <IoMdThumbsUp className="thumbs-icon" color="#5F6989" />
                  ) : (
                    <LuThumbsUp className="thumbs-icon " color="#5F6989" />
                  )}
                </button>
              </ProtectedComponent>

              <ProtectedComponent
                showModal={true}
                code={
                  checkResource ? "Candidate_general_feedback_write" : "no_code"
                }
              >
                <button
                  className="btn-md btn-sec w-10 p-[9px]"
                  data-tooltip-id="tooltip"
                  data-tooltip-content={"Dislike"}
                  onClick={() => handleThumbsPress(false)}
                >
                  {profile?.matchProfile?.thumbs_up === 2 ? (
                    <IoMdThumbsDown className="thumbs-icon" color="#5F6989" />
                  ) : (
                    <LuThumbsDown className="thumbs-icon" color="#5F6989" />
                  )}
                </button>
              </ProtectedComponent>

              <ProtectedComponent
                showModal={true}
                code={
                  checkResource ? "Candidate_general_feedback_write" : "no_code"
                }
              >
                <button
                  data-tooltip-id="tooltip"
                  data-tooltip-content={"Add notes"}
                  onClick={() => setShowModal({ type: "notes" })}
                  className="btn-md btn-sec w-10 p-[9px]"
                >
                  {notes}
                </button>
              </ProtectedComponent>

              {showActions && isCandidateSection && (
                <button
                  className="btn-md btn-sec w-10 p-[9px]"
                  data-tooltip-id="tooltip"
                  data-tooltip-content={"Review Mode"}
                  onClick={() => reviewMode()}
                >
                  <MdOutlinePreview />
                </button>
              )}

              {/* <button
                data-tooltip-id="tooltip"
                data-tooltip-content={"Apply other job"}
                className="btn-md btn-sec "
                onClick={handleApplyClick}
              >
                Apply other job
              </button> */}
              <ProtectedComponent
                showModal={true}
                code={checkResource ? "Candidate_update" : "no_code"}
              >
                <button
                  className="btn-md btn-sec w-10 p-[9px]"
                  data-tooltip-id="tooltip"
                  data-tooltip-content={"Apply other job"}
                  onClick={handleApplyClick}
                >
                  <VscGitStashApply />
                </button>
              </ProtectedComponent>

              {applyDropDown > -1 && candiadtejobmodal && (
                <ApplyForJobModal
                  jobId={profile?.matchProfile?.job}
                  id={applyDropDown}
                  candidate={candiadtejobmodal}
                  modalDismiss={handleModalDismiss}
                />
              )}

              {notesModalVisible && profile?.matchProfile && (
                <AddNotes
                  heading={
                    thumbsAction === "like"
                      ? "Like Feedback"
                      : "Dislike Feedback"
                  } // Different heading based on action
                  candidateDetail={{
                    name: profile?.matchProfile?.notes,
                    id: profile?.matchProfile?._id,
                    vote: thumbsAction === "like" ? true : false,
                  }}
                  modalDismiss={modalDismiss}
                  jobId={profile?.matchProfile?.job}
                  setContentReload={(newObj) => {
                    setprofile((prevProfile) => ({
                      ...prevProfile,
                      matchProfile: {
                        ...prevProfile.matchProfile,
                        notes: newObj.notes || [],
                        thumbs_up: newObj.thumbs_up,
                      },
                    }));

                    if (setContentReload) {
                      setContentReload(newObj);
                    }
                  }}
                />
              )}
            </div>
          </div>
        </div>
        {/* <div className="flex text-lg font-medium text-gray-700">
          <span className="mr-4">
            {profile?.matchProfile?.position}
          </span>
          <MdBusinessCenter className="text-3xl pb-[2px] text-gray-700" />
        </div> */}

        {/* Tabs list */}
        <div
          data-testid="btnList"
          className="flex justify-start items-center flex-row gap-6 mt-4"
        >
          {btnList.map((btn) => {
            const isActive = btn.name === activeTabName;
            const baseClassName = `text-base font-medium pt-2 pb-3.5 border-t-0 border-b-2 border-x-0 
          ${isActive ? "border-[#1369e9] text-[#16182f]" : "border-transparent text-[#252e4b]"} 
           border-solid hover:border-blue-600`;

            const handleClick = () => {
              setActiveTab(btn.component);
              setActiveTabName(btn.name);
            };

            const buttonContent = (
              <button
                key={btn.name}
                onClick={handleClick}
                data-testid={String(btn.name).toLowerCase()}
                className={baseClassName}
              >
                {btn.name}
              </button>
            );

            if (checkResource) {
              return (
                <ProtectedComponent showModal={true} code={btn?.code}>
                  {buttonContent}
                </ProtectedComponent>
              );
            }

            return buttonContent;
          })}
        </div>
      </div>
      {UploadModal && (
        <UploadLinksModal
          setReload={setReload}
          modalDismiss={() => {
            setShowUploadDocs(false);
          }}
        />
      )}
      {Modal === "Update_Can_data" ? (
        <>
          <ChangeCandidatesDataModal
            candidateDetail={{
              id: profile?.applicant_id,
              matchProfileId: profile?.matchProfile?._id,
              name: profile?.matchProfile?.first_name,
              last_name: profile?.matchProfile?.last_name,
              title: profile?.matchProfile?.title,
              email: profile?.matchProfile?.email,
              work_email: profile?.matchProfile?.work_email,
              phone: profile?.matchProfile?.phone,
              linkedln: profile?.matchProfile?.linkedin,
              source: profile?.matchProfile?.current_source,
              remote_source: profile?.matchProfile?.remote_source,
            }}
            loadData={(updatedData) => {
              handleCandidateEdit(updatedData);
              setCandidates(updatedData, candId);
            }}
            modalDismiss={() => setModal("")}
          />
        </>
      ) : Modal == "reject-reason-status" ? (
        <RejectedReasonModal
          updateRejectedStatus={updateStatus}
          modalDismiss={modalClose}
          chatDetail={chatDetail}
          jobId={profile?.matchProfile?.job}
          candidateId={chatDetail?.id}
        />
      ) : Modal == "send-email" ? (
        <SendEmail
          chatDetail={{
            ...profile?.applicant,
            summary: profile?.matchProfile?.matchSummary, // Add or update the 'summary' property
            matchId: profile?.matchProfile?.id ?? profile?.matchProfile?._id,
            _id: profile?.applicant_id,
          }}
          // companydata={companydata}
          candidateId={candidateDetail?.id}
          modalDismiss={modalClose}
        />
      ) : null}
    </>
    // )
  );
};

export default TopBanner;
