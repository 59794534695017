// MentionList.jsx
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

const MentionList = forwardRef((props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = (index) => {
    console.log("Selecting mention:", props.items[index], index);
    const item = props.items[index];

    if (item) {
      console.log("Selecting mention:", item);
      props.command({
        id: item._id.toString(),
        label: item.name ?? item.email,
      });
    }
  };
  const upHandler = () => {
    setSelectedIndex(
      (selectedIndex + props.items.length - 1) % props.items.length,
    );
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === "ArrowUp") {
        upHandler();
        return true;
      }

      if (event.key === "ArrowDown") {
        downHandler();
        return true;
      }

      if (event.key === "Enter") {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  return (
    <div className="mentions-menu">
      {props.items.length ? (
        <div className="bg-white rounded-lg shadow-lg border border-gray-200 overflow-hidden">
          {props.items.map((item, index) => (
            <button
              className={`
                w-full text-left px-4 py-2 
                ${index === selectedIndex ? "bg-blue-50" : "hover:bg-gray-50"}
              `}
              key={item.id}
              onClick={() => selectItem(index)}
            >
              <div className="font-medium text-gray-900">
                {item.name ?? item.email}
              </div>
              {/* <div className="text-sm text-gray-500">{item.email}</div> */}
            </button>
          ))}
        </div>
      ) : (
        <div className="px-4 py-2 text-gray-500">No results</div>
      )}
    </div>
  );
});

export default MentionList;
