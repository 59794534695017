import { useEffect, useRef, useState } from "react";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import InitialsLogo from "../InitialsLogo";
import CandidateDrawer from "../../recruiter/candidate/CandidateDrawer";
const core = require("../../../lib/core");
import { ScaleLoader } from "react-spinners";
import { ChevronDown } from "lucide-react";

export default function RecruiterNewSearchBar(props) {
  const [list, setList] = useState([]);
  const [hide, setHide] = useState(true);
  const [loading, setLoading] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [activeSearch, setActiveSearch] = useState("All");
  const [showCandidate, setShowCandidate] = useState(false);
  const [candId, setCandId] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  // console.log(
  //   hide + " hide",
  //   loading + " loading",
  //   noResults + " noResults",
  //   activeSearch + " activeSearch",
  //   showCandidate + " showCandidate",
  //   candId + " candId",
  //   showDropdown + " showDropdown"
  // );

  const nav = useNavigate();

  const buttonArray = ["All", "Job", "Candidate"];

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!e.target.closest("#category-dropdown")) {
        setShowDropdown(false);
      }
      if (
        !e.target.closest("#recruiter_searchbar") &&
        !e.target.closest(".search-menu")
      ) {
        setHide(true);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const getSearchResult = (searchItem) => {
    if (searchItem?.type == "Job" || searchItem?.type === "Location") {
      nav(`/job/active/${searchItem?._id}/description`);
    }
    if (searchItem?.type == "Candidate") {
      nav(`/job/${searchItem?.jobId}/candidate/${searchItem?._id}`);
    }
    if (searchItem?.type == "Company") {
      nav(`/company-profile/${searchItem?._id}`);
    }
    if (searchItem?.type === "Location") {
      nav(`/job/active/${searchItem?._id}/description`);
    }
  };

  function getStatusColor(status) {
    switch (status?.toLowerCase()) {
      case "active":
        return "bg-green-100 text-green-800";
      case "draft":
        return "bg-yellow-100 text-yellow-800";
      case "unprocessed":
        return "bg-blue-100 text-blue-800";
      case "deleted":
        return "bg-red-100 text-red-800";
      default:
        return "bg-gray-300 text-gray-800";
    }
  }

  let timeoutId; // Define timeoutId in the global scope to persist across calls

  const searchList = (searchTerm, item) => {
    try {
      console.log("searchTerm", searchTerm, item);
      setHide(false);
      setLoading(true);
      setNoResults(false);
      setList([]);

      // Clear any existing timeout to debounce API calls
      clearTimeout(timeoutId);

      // Return early if the search term is empty or too short
      if (!searchTerm || searchTerm.length < 1) {
        setLoading(false);
        setList([]);
        return;
      }

      // Determine the search category
      let searchFor = "";
      if (!item) {
        searchFor =
          activeSearch?.toLowerCase() === "all"
            ? ""
            : activeSearch.toLowerCase();
      } else {
        searchFor = item.toLowerCase();
      }

      // Set a timeout for the API call
      timeoutId = setTimeout(async () => {
        try {
          const response = await core.API(
            core.API_METHODS.POST,
            core.RECRUITER_API.GET_UNIVERSAL_SEARCH,
            1,
            {
              key: searchTerm,
              searchFor: searchFor,
            },
          );

          // Process the response data
          if (response?.data && response.data.length > 0) {
            setList(response.data);
            setNoResults(false);
          } else {
            setList([]);
            setNoResults(true);
          }
        } catch (err) {
          console.error("Error in search:", err);
          setList([]);
          setNoResults(true);
        } finally {
          setLoading(false);
        }
      }, 50); // Delay of 500ms for debouncing
    } catch (err) {
      console.error("Error in searchList:", err);
      setLoading(false);
      setNoResults(true);
    }
  };

  const renderNoResults = () => (
    <div className="py-4 text-center text-[#5F6989]">No results found</div>
  );

  const renderLoading = () => (
    <div className="flex justify-center items-center py-4">
      <ScaleLoader
        color="#1369E9"
        height={20}
        width={3}
        radius={2}
        margin={2}
      />
    </div>
  );

  const debounceTimeout = useRef(null);
  const debounceSearch = (searchTerm, item) => {
    clearTimeout(debounceTimeout.current);
    debounceTimeout.current = setTimeout(() => {
      searchList(searchTerm, item);
    }, 500); // Adjust the debounce time as needed
  };

  return (
    <div className="relative z-1000 w-full" id="recruiter_searchbar">
      <div className="w-[65%]  h-[40px] flex items-center bg-[#F3F5F9] rounded-lg focus-within:bg-[#FFF] focus-within:shadow-searchboxShadow">
        {/* Category Dropdown */}
        <div className="relative " id="category-dropdown">
          <button
            onClick={() => setShowDropdown(!showDropdown)}
            className="h-[40px] px-4 flex items-center gap-2 bg-[#E3E3E3] hover:bg-[#D5D5D5] rounded-l-lg border-r border-[#DADAE4] focus:outline-none"
          >
            <span className="text-sm font-medium min-w-[40px] capitalize">
              {activeSearch}
            </span>
            <ChevronDown className="h-4 w-4" />
          </button>

          {showDropdown && (
            <div
              style={{ zIndex: 1000 }}
              className="absolute top-full left-0 mt-1 w-48 bg-white rounded-lg shadow-lg border border-[#DADAE4]"
            >
              {buttonArray.map((item, idx) => (
                <button
                  key={`key${idx}`}
                  className={`w-full text-left px-4 py-2 text-sm hover:bg-[#F3F5F9] ${
                    activeSearch === item ? "bg-[#EFF0F2] font-medium" : ""
                  }`}
                  onClick={() => {
                    setActiveSearch(item);
                    setShowDropdown(false);
                    const inputElement = document.querySelector(
                      "#recruiter_searchbar input",
                    );
                    console.log("inputElement", inputElement?.value);

                    if (inputElement?.value) {
                      searchList(inputElement.value, item);
                      console.log("inputElement", inputElement?.value, item);
                    }
                  }}
                >
                  {item}
                </button>
              ))}
            </div>
          )}
        </div>

        {/* Search Input */}
        <div className="relative flex-grow">
          <svg
            className="absolute left-3 top-1/2 transform -translate-y-1/2"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <g id="Group 427319278">
              <path
                id="Star 8"
                d="M5.54474 2.67969C6.04291 5.38384 8.15878 7.49971 10.8629 7.99787C8.15878 8.49603 6.04291 10.6119 5.54474 13.3161C5.04658 10.6119 2.93071 8.49603 0.226562 7.99787C2.93071 7.49971 5.04658 5.38384 5.54474 2.67969Z"
                fill="#5F6989"
              />
              <path
                id="Star 9"
                d="M12.0904 0.632812C12.282 1.67287 13.0958 2.48667 14.1358 2.67827C13.0958 2.86987 12.282 3.68366 12.0904 4.72372C11.8988 3.68366 11.085 2.86987 10.0449 2.67827C11.085 2.48667 11.8988 1.67287 12.0904 0.632812Z"
                fill="#5F6989"
              />
              <path
                id="Star 10"
                d="M12.9086 9.63281C13.1768 11.0889 14.3161 12.2282 15.7722 12.4964C14.3161 12.7647 13.1768 13.904 12.9086 15.3601C12.6403 13.904 11.501 12.7647 10.0449 12.4964C11.501 12.2282 12.6403 11.0889 12.9086 9.63281Z"
                fill="#5F6989"
              />
            </g>
          </svg>
          <input
            className="h-12 w-full pl-10 rounded-r-lg placeholder-[#5F6989] placeholder-[14px] bg-transparent p-[6px] outline-none border-none"
            type="text"
            onFocus={() => setHide(false)}
            placeholder="Search for jobs or candidates..."
            onChange={(e) => {
              debounceSearch(e.target.value);
            }}
          />
        </div>
      </div>

      {/* Search Results Dropdown */}
      {!hide && (
        <div className="absolute w-[65%] bg-white mt-1 rounded-b-lg  z-50 shadow-searchboxShadow">
          <ul className="search-menu max-h-[400px]  overflow-y-auto p-[12px] scrollbar-hide ">
            {loading && renderLoading()}
            {!loading && noResults && renderNoResults()}
            {!loading &&
              !noResults &&
              (activeSearch === "All"
                ? ["Job", "Candidate"]
                : [activeSearch]
              ).map((category) => (
                <div key={category} className="mt-[18px]">
                  <span className="text-[#5F6989] text-[12px] font-normal">
                    {category}
                  </span>
                  {list
                    ?.filter(
                      (item) =>
                        item.type === category ||
                        (category === "Location" && item.type === "Job"),
                    )
                    .map((item, i) => (
                      <li
                        key={i}
                        className="mt-[12px] cursor-pointer"
                        onClick={() => {
                          if (category === "Candidate") {
                            setShowCandidate(true);
                            setCandId(item?.matchedProfiles[0]?._id);
                          } else {
                            getSearchResult(item);
                          }
                        }}
                      >
                        <div className="flex items-center p-1">
                          <InitialsLogo
                            width={"26px"}
                            height={"26px"}
                            style={{ display: "inline-block" }}
                            str={
                              item?.name ||
                              `${item?.first_name} ${item?.last_name || ""}`
                            }
                          />
                          <span className="ml-[8px] text-[#252E4B] text-[12px] font-normal">
                            {item?.name ||
                              `${item?.first_name} ${item?.last_name || ""}`}
                          </span>
                          {item?.status && (
                            <span
                              className={`text-[10px] font-medium px-2 py-1 rounded-full ml-2 ${getStatusColor(
                                item.status,
                              )}`}
                            >
                              {item.status}
                            </span>
                          )}
                          {item?.title && (
                            <span className="ml-[8px] text-[#252E4B] text-[12px] text-blue-800 font-normal">
                              {item.title}
                            </span>
                          )}
                        </div>
                      </li>
                    ))}
                </div>
              ))}
          </ul>
        </div>
      )}

      {showCandidate && (
        <CandidateDrawer setShowCandidate={setShowCandidate} candId={candId} />
      )}
    </div>
  );
}
